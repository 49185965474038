
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService from "@/core/services/AppService";
import { News } from "@/store/model/News";
import { AccountData } from "@/store/modules/AccountsModule";
import { AdminModel } from "@/store/model";

export default defineComponent({
  name: "edit-account",
  props: ["id"],
  components: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataRoles = ref([]);
    let objectData = ref<AdminModel>({} as AdminModel);
    onMounted(async () => {
      await store.dispatch("GET_ACCOUNT_DETAIL", props.id);
      await store.dispatch("GET_ROLES_DATA", AppService.convertParams(""));
      listDataRoles.value = store.getters["listDataRoles"];
      objectData.value = await store.getters["currentAccount"];
      setCurrentPageBreadcrumbs("Cập nhật quyền", ["Người dùng"]);
      MenuComponent.reinitialization();
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Họ tên không được để trống",
          trigger: "blur",
        },
      ],
      role: [
        {
          required: true,
          message: "Chọn ít nhất 1 quyền ",
          trigger: "blur",
        },
      ],
      password: [
        {
          min: 6,
          message: "Tối thiểu 6 ký tự",
          trigger: "blur",
        },
      ],
    });
    const changeStatus = () => {
      objectData.value["status"] = objectData.value["action"]
        ? "active"
        : "inactive";
      // { "code_number" : 15067 }14751
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          // loading.value = true;
          if (
            typeof objectData.value.password === "string" &&
            objectData.value.password.trim() === ""
          ) {
            objectData.value.password = null;
          }
          store
            .dispatch("UPDATE_ACCOUNT", objectData.value)
            .then((data) => {
              //console.log(data);
              Swal.fire({
                text: "Cập nhật thông tin thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                // Go to page after successfully login
                if (result.isConfirmed) {
                  router.push({ name: "accounts" });
                }
              });
            })
            .catch((data) => {
              //console.log("console", data);
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      submit,
      changeStatus,
      loading,
      objectData,
      listDataRoles,
      formRef,
    };
  },
});
